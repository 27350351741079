import { computed } from "@vue/composition-api";
import store from "@/store";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
import { duration } from "moment";
import i18n from '@/libs/i18n'
/*
import AccelerationValuesData from "../../dummy-db/AccelerationValues.json"
import AxialVibrationValuesData from "../../dummy-db/AxialVibrationValues.json"
import RadialVibrationValuesData from "../../dummy-db/RadialVibrationValues.json"
import RadialAxisKurtosisValuesData from "../../dummy-db/RadialAxisKurtosisValues.json"
*/
export default function useSimulationValuesData(selectedValueType) {
    const dropdownMap = new Map([
        ["Acceleration", "Acceleration"],
        ["Axial Vibration", "AxialVibration"],
        ["Radial Axis Kurtosis", "RadialAxisKurtosis"],
        ["Radial Vibration", "RadialVibration"],
        ["Temperature", "Temperature"]]
    )
    const tableColumns = [
        { key: 'name', label : 'Line', sortable: true, },
        { key: 'currentValue', label : 'Current Value', sortable: true },
        { key: 'simulationValue', label : 'Simulation Value', sortable: true },
        { key: 'startTime', label : 'Start Time',  sortable: false },
        { key: 'duration', label : 'Simulation Duration', sortable: false },
        { key: 'endTime', label : 'End Time', sortable: false },
        { key: 'action',label : 'Action', sortable: false },
    ]
    const tableData = computed(() => {
        let AccelerationData = store.getters[`pamis-maintenance/getAccelerationValuesData`]
        AccelerationData.forEach(e => e.Type = "Acceleration")

        let AxialVibrationData = store.getters[`pamis-maintenance/getAxialVibrationValuesData`]
        AxialVibrationData.forEach(e => e.Type = "AxialVibration")

        let RadialAxisKurtosisData = store.getters[`pamis-maintenance/getRadialAxisKurtosisValuesData`]
        RadialAxisKurtosisData.forEach(e => e.Type = "RadialAxisKurtosis")

        let RadialVibrationData = store.getters[`pamis-maintenance/getRadialVibrationValuesData`]
        RadialVibrationData.forEach(e => e.Type = "RadialVibration")

        let TemperatureData = store.getters[`pamis-maintenance/getTemperatureValuesData`]
        TemperatureData.forEach(e => e.Type = "Temperature")

        let data = [
            ...AccelerationData,
            ...AxialVibrationData,
            ...RadialAxisKurtosisData,
            ...RadialVibrationData,
            ...TemperatureData
        ];
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        const seriesValues = []
        for (let i = 0, c = 0; i < data.length; i++) {
            for (let wc = 0; wc < workcenters.length; wc++) {
                seriesValues.push({
                    name: workcenters[wc],
                    type: data[i].Type,
                    currentValue: data[i][workcenters[wc]],
                    simulationValue: "-",
                    startTime: new Date((new Date(data[i].Time)).getTime()),
                    duration: "-",
                    endTime: new Date((new Date(data[i].Time)).getTime() + 50 * 1000),
                    status: "Not Started",
                    index: i,
                    tableIndex: c
                    
                })
                c++
            }
        }
        return seriesValues


    });
    return {
        tableColumns,
        tableData,
        dropdownMap,
    }
}
